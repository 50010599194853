<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
