// @ts-nocheck
import Vue from 'vue'
import VueRouter from 'vue-router'

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/enter',
        name: 'Enter',
        component: () => import('@/views/Enter.vue')
    },
    {
        path: '/group',
        name: 'Group',
        component: () => import('@/views/Group.vue')
    },
    {
        path: '/duty',
        name: 'Duty',
        component: () => import('@/views/Duty.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
